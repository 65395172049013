button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.blob{
    /* background: black; */
    border-radius: 50%;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(2);
	animation: pulse 2s infinite;
}

button:hover{
    transform: scale(1.1);
}


@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}