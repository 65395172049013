@import url('https://fonts.googleapis.com/css?family=VT323');

.container {
  margin: 0 auto;
  max-width: 55rem;
  padding: 2.625rem 1.3125rem;
}

.title {
/*  font-family: Montserrat, sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.title > a {
  color: var(--primary-color-light);
  text-decoration: none;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Menlo-Regular', monospace; */
}

.bannerContainer{
  font-family: 'Menlo-Regular', monospace;
  /* font-size: calc(2vmin); */
  min-height: 100vh;
  color: white;
  background-color: #2A363B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
