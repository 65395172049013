.cursor {
  border-left: .2em solid;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.bannerContent {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}
