@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323);
/* @import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900'); */
/* https://fonts.google.com/specimen/Roboto?selection.family=Roboto:100,400 */

:root {
  --primary-color: #2A363B;
  --primary-color-light: #2A363B;
}

* {
  box-sizing: border-box;
}

html {
  /* color: rgba(0, 0, 0, 0.9); */
  color: #2A363B;
  background-color:  #E8E8E8;
  /* font: 100%/1.75 Morriweather, Georgia, serif */
  font: 100%/1.75 Roboto, sans-serif;
}

body {
  font-kerning: normal;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Merriweather','Georgia',serif; */
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "kern";
          font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

pre,
code {
  /* font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace; */
  font-family: 'Menlo-Regular', monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: #2A363B;
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}


#src_cursor__3LslZ {
  border-left: .2em solid;
  -webkit-animation: src_blink__2WSvx 1s steps(1) infinite;
          animation: src_blink__2WSvx 1s steps(1) infinite;
}

@-webkit-keyframes src_blink__2WSvx {
  50% {
    border-color: transparent;
  }
}

@keyframes src_blink__2WSvx {
  50% {
    border-color: transparent;
  }
}

.ArticleMeta_tags__1IJ9w,
.ArticleMeta_tags__1IJ9w li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__1IJ9w li:not(:first-child)::before {
  content: ', ';
}
.ArticleSummary_ArticleSummary__3I5Qk h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__3I5Qk a {
  text-decoration: none;
}
.Bio_Bio__1ks_2 {
  display: flex;
  margin-bottom: 4.375rem;
}
  
.Bio_Bio__1ks_2 img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}
.Pagination_Pagination__2Xt2h {
  display: block;
  margin: 4.375rem 0 7rem;
}
  
.Pagination_Pagination__2Xt2h .Pagination_previous__1akUm {
  margin-right: 0.5rem;
}
.Pagination_Pagination__2Xt2h .Pagination_next__7Ba4Y {
  margin-left: 0.5rem;
}
.BlogIndexPage_title__341hM {
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
}
.BlogIndexPage_title__341hM a {
  color: black;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}

.BlogIndexPage_articlesList__1V39R,
.BlogIndexPage_articlesList__1V39R > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.BlogIndexPage_footer__1vkZQ {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}


.LoadingIndicator_LoadingIndicator__3KSl0 {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition-delay: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__3KSl0.LoadingIndicator_active__3EncM {
  opacity: 1;
  transition-delay: 333ms;
  -webkit-animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
          animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

.BlogLayout_container__1fiRb {
  margin: 0 auto;
  max-width: 55rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__3HyDU {
/*  font-family: Montserrat, sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__3HyDU > a {
  color: var(--primary-color-light);
  text-decoration: none;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Menlo-Regular', monospace; */
}

.BlogLayout_bannerContainer__3a6gm{
  font-family: 'Menlo-Regular', monospace;
  /* font-size: calc(2vmin); */
  min-height: 100vh;
  color: white;
  background-color: #2A363B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.Banner_cursor__1v2pT {
  border-left: .2em solid;
  -webkit-animation: Banner_blink__23ed1 1s steps(1) infinite;
          animation: Banner_blink__23ed1 1s steps(1) infinite;
}

@-webkit-keyframes Banner_blink__23ed1 {
  50% {
    border-color: transparent;
  }
}

@keyframes Banner_blink__23ed1 {
  50% {
    border-color: transparent;
  }
}

.Banner_bannerContent__2nUG9 {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.PageScroller_blob__2XQpt{
    /* background: black; */
    border-radius: 50%;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	-webkit-transform: scale(2);
	        transform: scale(2);
	-webkit-animation: PageScroller_pulse__2oCdc 2s infinite;
	        animation: PageScroller_pulse__2oCdc 2s infinite;
}

button:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}


@-webkit-keyframes PageScroller_pulse__2oCdc {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


@keyframes PageScroller_pulse__2oCdc {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.BlogPostLayout_title__2L_2_ {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__2L_2_ > a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Menlo-Regular', monospace; */
}

.BlogPostLayout_header__1Ny4T {
  margin-bottom: 2.4rem;
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ > a {
  color: black;
}

.BlogPostLayout_footer__3ofxa {
  margin-top: 7rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__diNn9 {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__-LX2X {
  display: flex;
  justify-content: space-between;
}
.BlogPostLayout_links__-LX2X .BlogPostLayout_next__KrMHx {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__1mzTO hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__1mzTO h2,
.BlogPostLayout_content__1mzTO h3,
.BlogPostLayout_content__1mzTO h4,
.BlogPostLayout_content__1mzTO h5,
.BlogPostLayout_content__1mzTO h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}

/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__1mzTO code,
.BlogPostLayout_content__1mzTO pre {
  color: white;
  /* font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace; */
  font-family: 'Menlo-Regular', monospace;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}

/* Code blocks */
.BlogPostLayout_content__1mzTO pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__1mzTO :not(pre) > code,
.BlogPostLayout_content__1mzTO pre {
  background: hsla(0,0%,0%,0.9);
}

.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::selection {
  background: hsl(207, 4%, 16%);
}

/* Text Selection colour */

.BlogPostLayout_content__1mzTO pre::selection,
.BlogPostLayout_content__1mzTO pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__1mzTO :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__1mzTO a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__1mzTO .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__1mzTO .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__1mzTO .token.string,
.BlogPostLayout_content__1mzTO .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__1mzTO .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__1mzTO .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__1mzTO .token.builtin,
.BlogPostLayout_content__1mzTO .token.char,
.BlogPostLayout_content__1mzTO .token.constant,
.BlogPostLayout_content__1mzTO .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__1mzTO .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__1mzTO .token.selector,
.BlogPostLayout_content__1mzTO .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__1mzTO .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__1mzTO .token.tag,
.BlogPostLayout_content__1mzTO .token.operator,
.BlogPostLayout_content__1mzTO .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__1mzTO .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__1mzTO .token.namespace {
  color: rgb(178, 204, 214);
}

