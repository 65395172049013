/* @import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900'); */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=VT323');
/* https://fonts.google.com/specimen/Roboto?selection.family=Roboto:100,400 */

:root {
  --primary-color: #2A363B;
  --primary-color-light: #2A363B;
}

:global * {
  box-sizing: border-box;
}

:global html {
  /* color: rgba(0, 0, 0, 0.9); */
  color: #2A363B;
  background-color:  #E8E8E8;
  /* font: 100%/1.75 Morriweather, Georgia, serif */
  font: 100%/1.75 Roboto, sans-serif;
}

:global body {
  font-kerning: normal;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Merriweather','Georgia',serif; */
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

:global(pre),
:global(code) {
  /* font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace; */
  font-family: 'Menlo-Regular', monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
:global pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

:global a {
  color: var(--primary-color);
}
:global a:hover {
  text-decoration: none;
}

:global p,
:global pre,
:global ul,
:global ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}


#cursor {
  border-left: .2em solid;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
